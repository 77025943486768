<template>
  <div class="batteryDetail flex">
    <div class="fixed top-0 min-w-full bg-gray-600 md:pt-6 pb-48 pt-12" />
    <div class="flex flex-wrap justify-between px-8 md:px-14 w-full">
      <div class="w-full lg:w-full">
        <SlideUp>
          <Form :form="formData" formName="asset detail" />
        </SlideUp>
      </div>
    </div>
  </div>
</template>

<script>
import SlideUp from "@/components/transitions/SlideUp.vue";
import Form from "@/components/Form.vue";
import { mapState } from "vuex";
import formatting from "../mixins/formatting";
import { bindData } from "../helpers/form";
import customData from "../mixins/customData";
export default {
  name: "BatteryDetail",
  components: {
    SlideUp,
    Form
  },
  mixins: [formatting, customData],
  computed: {
    ...mapState("assets", ["asset"])
  },
  data() {
    const { hubs } = this.$store.state.hubs;
    return {
      formData: [
        {
          section: "Asset Information",
          dispatch:
            this.$route.params.id != "new"
              ? "assets/updateAsset"
              : "assets/createAsset",
          fields: [
            { key: "id", name: "id", type: "hidden", value: "" },
            {
              name: "name",
              key: "name",
              value: "",
              type: "text"
            },
            {
              name: "hub",
              key: "hub_id",
              value: "",
              type: "select",
              options: hubs,
              optionLabelKey: "name"
            },
            { key: "notes", value: "", name: "notes", type: "textarea" }
          ]
        }
      ]
    };
  },
  watch: {
    asset() {
      bindData(this.formData, this.asset);
    }
  },
  mounted() {
    if (this.$route.params.id != "new") {
      this.$store.dispatch("assets/getAsset", this.$route.params.id);
    } else {
      this.$store.commit("assets/RESET_ASSET");
    }
  },
  created() {
    this.getSettings("asset", "asset");
  }
};
</script>
